<template>
  <div class="help-box">
    {{$t('help.'+context)}}
  </div>
</template>

<script>

export default {
  name: 'HelpBox',
  components: {},
  props: {
    context: String
  }
};
</script>

<style>
.help-box {
  background-position: right top;
  background-size: 2em;
  background-repeat: no-repeat;
  background-image: url('~@/assets/img/help.svg');
}
.help-box {
  margin: 0.5em;
  padding: 1em 1.5em 0.5em 0.5em;
  border: 2px solid #aaffff;
  background-color: #ddffff;
  color: black;
}
.help-box:hover{
  color: black;
}
</style>
