<template>
  <ErrorMessageBox v-if="error" :error="error"></ErrorMessageBox>
  <div v-else class="grid-list">
    <div class="grids" v-if="Grids">
      <table class="grid-list-table">
        <tr v-for="grid in Grids" :key="grid.id">
          <td id="grid-id">
            {{ grid.width }}x{{grid.height}}
          </td>
          <td id="grid-img">
            <img :src="grid.image" class="grid-thumb"
                 :width="grid.width * previewSizeMultiplier"
                 :height="grid.height * previewSizeMultiplier"
            >
          </td>
        </tr>
      </table>
    </div>
    <div v-else v-t="'grids.empty'"></div>
  </div>
</template>


<script>
import ErrorMessageBox from "@/components/ErrorMessageBox";
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'GridList',
  components: {ErrorMessageBox},
  data() {
    return {
      previewSizeMultiplier: 8,
      error: null
    }
  },
  created: async function () {
    try {
      await this.GetGrids()
    } catch (error) {
      if (error.status == 401) {
        await this.$store.dispatch('logoutAction')
        await this.$router.push('/login')
      } else {
        this.error = error
        this.$emit("error")
      }
    }

  },
  computed: {
    ...mapGetters({Grids: "StateGrids"}),
  },
  methods: {
    ...mapActions(["GetGrids"])
  }
};
</script>

<style>
div.grids {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.grid-list-table {
  border-collapse: collapse;
  flex-shrink: 1;
  align-self: center
}

.grid-list-table td {
  border: 0.05em solid black;
  padding: 0.5em
}

.grid-thumb {
  image-rendering: pixelated;
  box-shadow: 0px 0px 5px #606060;
}
</style>
