<template>
  <div class="grid-list">
    <div class="grid-list-flex">
      <div v-for="grid in Grids" :key="grid.id"
           :class="value == grid.id ? 'selected' : ''"
           @click="inputValue(grid.id)">
        <img :src="grid.image" class="grid-thumb"
             :width="grid.width * previewSizeMultiplier"
             :height="grid.height * previewSizeMultiplier"
        >
      </div>
    </div>
  </div>
</template>


<script>
//import ButtonCreate from '@/components/ButtonCreate.vue'
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'GridList',
  components: {},
  data() {
    return {
      previewSizeMultiplier: 8
    }
  },props: {
    value: {
      type: Number
    }
  }
  ,
  created: async function () {
    await this.GetGrids()
    if (this.Grids.length > 0) {
      this.inputValue(this.Grids[0].id)
    }
  },
  computed: {
    ...mapGetters({Grids: "StateGrids"}),
  },
  methods: {
    ...mapActions(["GetGrids"]),
    inputValue(value){
      this.$emit('input', value)
    }
  }
};
</script>

<style>
div.grids {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.grid-list-flex {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  align-self: center
}

.grid-list-flex > div {
  padding: 1em;
  margin: 0.1em;
  border: 1px solid #606060;
}

.grid-list-flex > div.create {
  border-style: none;
}

.grid-thumb {
  image-rendering: pixelated;
  box-shadow: 0px 0px 5px #606060;
}

.selected {
  background-color: #9999ff;
}
</style>
