import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";

import auth from './modules/auth';
import tasks from './modules/tasks';
import grids from './modules/grids';

// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
    modules: {
        auth,
        tasks,
        grids
    },
    plugins: [createPersistedState()]
});
