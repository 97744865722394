<template>
  <ErrorMessageBox v-if="error" error="error"></ErrorMessageBox>
  <div v-else class="task-list">
    <div v-if="createForm">
      <TaskCreate v-if="createForm" @exit="createForm = false" @created="createForm = false"></TaskCreate>
    </div>
    <div v-else>
      <ButtonCreate @click="createForm = true" :objectName="$t('tasks.object')"
                    class="button-create-task"></ButtonCreate>
      <div class="tasks" v-if="Tasks">
        <table class="task-list-table">
          <tr v-for="task in Tasks" :key="task.id">
            <td class="task-status" @click="taskClick(task)">
              <img :src="statusImage(task)" alt="task.status">
            </td>
            <td class="task-created-at" @click="taskClick(task)">
              {{ task.createdAt | moment("lll") }}
            </td>
            <td class="task-grid" @click="taskClick(task)">
              <img :src="task.Grid.image" :alt="`Grid ${task.Grid.id}`">
            </td>
            <td class="task-grid-size" @click="taskClick(task)">
              {{ `${task.Grid.width}x${task.Grid.height}` }}
            </td>
            <td class="task-grid-size" @click="taskClick(task)">
              {{ humanizeCategorization(task) }}
            </td>
            <td class="task-grid-size">
              <ButtonTrash @click="trash(task)"></ButtonTrash>
            </td>
          </tr>
        </table>
      </div>
      <div v-else v-t="'tasks.empty'"></div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions} from "vuex";
import ButtonCreate from '@/components/ButtonCreate.vue'
import ButtonTrash from '@/components/ButtonTrash.vue'
import TaskCreate from '@/components/TaskCreate.vue'
import ErrorMessageBox from "@/components/ErrorMessageBox";

export default {
  name: 'TaskList',
  components: {ButtonCreate, ButtonTrash, TaskCreate, ErrorMessageBox},
  data() {
    return {
      createForm: false,
      showModal: false,
      error: null
    }
  },
  created: async function () {
    try {
      await this.GetTasks()
    } catch (error) {
      if (error.status == 401) {
        await this.$store.dispatch('logoutAction')
        await this.$router.push('/login')
      } else {
        this.error = error
        this.$emit("error")
      }
    }
  },
  computed: {
    ...mapGetters({Tasks: "StateTasks"}),
  },
  methods: {
    ...mapActions(["CreateTask", "DeleteTask", "GetTasks"]),
    async submitTask() {
      try {
        await this.CreateTask(this.form);
      } catch (error) {
        if (error.status == 401) {
          await this.$store.dispatch('logoutAction')
          await this.$router.push('/login')
        } else {
          this.error = error
          this.$emit("error")
        }
      }
    },
    statusImage(task) {
      if (task.status == 'created') {
        return require('../assets/img/task-status/created.svg')
      } else if (task.status == 'error') {
        return require('../assets/img/task-status/error.svg')
      } else if (task.status == 'success') {
        return require('../assets/img/task-status/success.svg')
      } else if (task.status == 'unfeasible') {
        return require('../assets/img/task-status/error.svg')
      } else {
        return require('../assets/img/task-status/unknown.svg')
      }
    },
    humanizeCategorization(task) {
      const numberValues = Object.values(task.CategorizationPreference.value).map(parseFloat).filter(val => !Number.isNaN(val))
      const maxValue = Math.max(...numberValues)
      let catMaxes = []
      for (const [cat, value] of Object.entries(task.CategorizationPreference.value)) {
        if (parseFloat(value) === maxValue) {
          catMaxes.push(cat)
        }
      }
      return catMaxes.join(', ')
    },
    async taskClick(task) {
      if (task.status == 'success') {
        await this.$router.push('/crossword/' + task.id)
      }
    },
    async trash(task) {
      this.$confirm(
          {
            message: this.$t('confirm.task.remove', {taskId: task.id}),
            button: {
              no: this.$t('confirm.no'),
              yes: this.$t('confirm.yes')
            },
            callback: async confirm => {
              if (confirm) {
                await this.DeleteTask(task);
              }
            }
          })
    }
  }
};
</script>

<style>
div.tasks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.task-list-table {
  border-collapse: collapse;
  flex-shrink: 1;
  align-self: center
}

.task-list-table td {
  border: 0.05em solid black;
  padding: 0 0.3em;
}

.task-status, .task-grid {
  padding: 0 !important;
}

.task-status img {
  max-width: 2.4em;
  max-height: 2.4em;
  vertical-align: middle;
}

.task-grid img {
  min-width: 2.4em;
  max-height: 2.4em;
  vertical-align: middle;
  image-rendering: pixelated;
}
</style>
