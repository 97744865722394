<template>
  <div class="task-create-container">
    <div class="task-create">
      <div class="task-create-menu-row">
        <ButtonClose @click="$emit('exit')"></ButtonClose>
        <h2 v-t="'tasks.new'"></h2>
        <ButtonSave @click="submitTask()"  :objectName="$t('tasks.object')"></ButtonSave>
      </div>
      <div class="row">
        <div v-t="'grids.title'"></div>
        <GridListSelectable v-model="gridId"></GridListSelectable>
      </div>
    </div>
    <CategorizationPreferences @change="newval => categorizationPreference = newval"></CategorizationPreferences>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import ButtonClose from "@/components/ButtonClose";
import ButtonSave from "@/components/ButtonSave";
import GridListSelectable from '@/components/GridListSelectable.vue'
import CategorizationPreferences from '@/components/CategorizationPreferences.vue'

export default {
  name: 'TaskCreate',
  components: {ButtonSave, ButtonClose, GridListSelectable, CategorizationPreferences},
  data() {
    return {
      maxWidth: 15,
      maxHeight: 15,
      width: 5,
      height: 5,
      table: [],
      componentKey: 0,
      categorizationPreference: {},
      gridId: null
    }
  },
  created: function () {
    this.table = []
    for (let y = 0; y <= this.maxHeight; y++) {
      this.table.push([])
      for (let x = 0; x <= this.maxWidth; x++) {
        if (y == 1 || x == 1) {
          this.table[y].push('X')
        } else {
          this.table[y].push(' ')
        }
      }
    }
  },
  computed: {
    ...mapGetters({Tasks: "StateTasks"}),
  },
  methods: {
    ...mapActions(["CreateTask"]),
    async submitTask() {
      try {
        // Serialize this task

        const taskObject = {
          grid_id: this.gridId,
          categorizationPreference: this.categorizationPreference
        }
        console.log(JSON.stringify(taskObject,null,3))
        await this.CreateTask(taskObject);

        this.$emit('created')
      } catch (error) {
        this.$emit("error")
        throw "Sorry you can't make this now!"
      }
    },
    range(start, stop, step) {
      let a = [start], b = start;
      while (b < stop) {
        a.push(b += step || 1);
      }
      return a;
    },
    toggle(x, y) {
      if (this.table[x][y] == 'X') {
        this.table[x][y] = ' '
      } else {
        this.table[x][y] = 'X'
      }
      this.componentKey++
    }

  }
};
</script>

<style>
.task-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 25em;
  margin: 0 auto;
}

.task-create-menu-row {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-self: center;
  justify-self: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #d0d0d0
}
</style>
