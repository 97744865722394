<template>
  <div class="categorization-preferences">
    <div v-for="(categorizationDescription, cat) in categorization" :key="cat">
      <label :for="cat">{{ categorizationDescription }}</label>
      <input type="range" :name="cat" min="-1" max="1" step="0.1" v-model="values[cat]" @change="$emit('change', getValues())">
    </div>
  </div>
</template>

<script>

export default {
  name: 'CategorizationPreferences',
  components: {},
  props: [],
  data() {
    return {
      categorization: {
        'AGR': 'zemědělství, chovatelství',
        'ANT': 'antropologie, entografie',
        'ART': 'umění, architektura',
        'BIO': 'biologie',
        'CHE': 'chemie',
        'ECO': 'ekonomie, obchod, logistika',
        'EDU': 'pedagogika',
        'HIS': 'historie, biografie',
        'ICT': 'vypočetní technika',
        'INF': 'knihovnictví, informatika',
        'ITD': 'interdisciplinární',
        'LAN': 'filologie',
        'LAW': 'právo',
        'MAT': 'matematika',
        'MED': 'lékařství',
        'MUS': 'hudba',
        'PHI': 'filozofie nabozenstvi',
        'PHY': 'fyzika',
        'POL': 'politika vojenstvi',
        'PSY': 'psychologie',
        'REC': 'sport, rekreace, hobby',
        'SOC': 'sociologie',
        'GEO': 'geologie, geografie',
        'TEC': 'technologie',
        'THE': 'divadlo, film, tanec',
        'ADM': 'administrativa',
        'MEM': 'memoáry, autobiografie',
        'NTW': 'celostátní tisk',
        'REG': 'regionální tisk',
        'HOU': 'bydlení, zahrada, hobby',
        'INT': 'zajimavosti ze světa',
        'LIF': 'životní styl',
        'MIX': 'společnost',
        'SCT': 'společenský život',
        'SPO': 'sport'
      },
      values: {}
    }
  },
  methods: {
    created: function () {
    },
    getValues: function(){
      return this.values
    }
  }
};
</script>

<style>
.categorization-preferences {
  width: auto;
  margin: 0 auto;
  max-width: 30em;
}

.categorization-preferences > div {
  padding-top: 0.3em;
  border-bottom: 1px solid #606060;
  display:flex;
  justify-content: space-between;
  align-items: flex-end;
}

.categorization-preferences label {
  font-size: 100%;
  min-width: auto;
}

.categorization-preferences input {
  font-size: 100%;
  margin: 0;
  padding: 0;
  width: 8em;
}

.categorization-preferences {
  display: flex;
  flex-direction: column;
}
</style>
