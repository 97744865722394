<template>
  <nav>
    <ul class="navmenu">
      <li v-if="isLoggedIn && hasGrids">
        <router-link to="/" :class="!hasTasks ? 'tutorial tutorial2' : ''" v-t="'tasks.title'"></router-link>
      </li>
      <li v-if="isLoggedIn">
        <router-link to="/grids" :class="!hasGrids ? 'tutorial tutorial1' : ''" v-t="'grids.title'"></router-link>
      </li>
      <li v-if="isLoggedIn">
        <a @click="logout" v-t="'menu.logout'"></a>
      </li>
      <li v-if="!isLoggedIn">
        <router-link to="/signup" v-t="'menu.signup'"></router-link>
      </li>
      <li v-if="!isLoggedIn">
        <router-link to="/login" v-t="'menu.login'"></router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'NavBar',
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated
    },
    hasGrids: function(){
      return this.$store.getters.hasGrids
    },
    hasTasks: function(){
      return this.$store.getters.hasTasks
    }
  },
  methods: {
    async created(){
      await this.GetGrids()
      await this.GetTasks()
    },
    async logout() {
      await this.$store.dispatch('logoutAction')
      await this.$router.push('/login')
    },
    ...mapActions(["GetGrids"])
  },
}
</script>

<style>
nav {
  border-bottom: 1px solid #808080;
}

nav a {
  font-weight: bold;
  letter-spacing: 0.06em;
  color: #2c3e50;
}

a:hover {
  cursor: pointer;
}

.navmenu {
  list-style-type: none;
  display:flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
}
.navmenu li {
  padding: 0vh;
  margin:  0 1vh;
}
.navmenu li a {
  text-decoration: none !important;
  padding: 2vh;
  border-radius: 2vh;
}
.navmenu li a:hover {
  background-color: #e0e0e0;
  color: black;
}

.navmenu li a.tutorial {
  padding: 0;
  padding-right: 1em;
}
.tutorial {
  display: flex;
  border-radius: 2vh;
  align-items: center;
  border: 2px solid black;
}
.tutorial1 {
  text-decoration: none !important;
}
.tutorial:before {
  border-radius: 2vh;
  border: 2px solid black;
  margin: -2px 0.3em -2px 0;
  border-left-style: none;
  text-decoration: none !important;
  background-color: #f0f0a0;
}
.tutorial:before {
  content: '→';
  font-size: 200%;
  font-weight: bold;
}
</style>
