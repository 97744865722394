<template>
  <div class="button-create" @click="$emit('click')">
    <img src="../assets/img/add.svg" :alt="$t('button.create', {objectName: objectName})" class="button-create-img">
  </div>
</template>

<script>

export default {
  name: 'ButtonCreate',
  components: {},
  props: ['objectName']
};
</script>

<style>
.button-create-img {
  max-width: 10vh;
  max-height: 10vw;
  opacity:0.8;
}
.button-create-img:hover{
  opacity:1;
}
</style>
