<template>
  <main>
    <div v-if="this.$store.getters.isAuthenticated">
      <div v-if="createForm">
        <GridCreate v-if="createForm" @exit="createForm = false" @created="createForm = false"></GridCreate>
      </div>
      <div v-else>
        <ButtonCreate @click="createForm = true" :objectName="$t('grids.object')" class="button-create-grid"></ButtonCreate>
        <GridList></GridList>
      </div>
    </div>
    <div v-else v-t="'home.pleaseLogin'"></div>
  </main>
</template>

<script>
import GridList from '@/components/GridList.vue'
import GridCreate from '@/components/GridCreate.vue'
import ButtonCreate from '@/components/ButtonCreate.vue'


export default {
  name: "Grids",
  components: {GridList, GridCreate, ButtonCreate},
  data() {
    return {
      createForm: false
    }
  },
  methods: {}
};
</script>

<style>
main {
  max-width: 80em;
  margin: 0 auto;
  position:relative;
}

.button-create-grid {
  position: absolute;
  right: 1em
}
</style>
