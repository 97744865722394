import Vue from 'vue'
import axios from 'axios'
import router from './router';
import store from './store';
import App from './App.vue'
import i18n from "./i18n";
import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(require('vue-moment'));
Vue.config.productionTip = false

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

axios.defaults.withCredentials = true

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + '/v1/';

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
