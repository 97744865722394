//store/modules/auth.js
import axios from 'axios';

const state = {
    tasks: null,
    crosswords: {}
};
const getters = {
    StateTasks: state => state.tasks,
    StateCrosswordById: (state) => (crosswordId) => { return state.crosswords[crosswordId] }
};
const actions = {
    async CreateTask({dispatch}, task) {
        // todo: connect correctly
        const result = await axios.post('tasks', task, {
            validateStatus: status => (status >= 200 && status < 500),
            headers: {
                "Authorization": `Bearer ${this.state.auth.authenticateToken}`
            }
        })
        if (result.status === 200) {
            // refresh list
            await dispatch('GetTasks')
        } else {
            throw({status: result.status, message: result.data.message})
        }
    },
    async DeleteTask({dispatch}, task) {
        const result = await axios.delete('tasks/'+task.id, {
            validateStatus: status => (status >= 200 && status < 500),
            headers: {
                "Authorization": `Bearer ${this.state.auth.authenticateToken}`
            }
        })
        if (result.status === 200) {
            // refresh list
            await dispatch('GetTasks')
        } else {
            throw({status: result.status, message: result.data.message})
        }
    },
    async GetTasks({commit}) {
        const user = this.state.auth.user
        const result = await axios.get('/users/' + user.id + '/tasks',
            {
                validateStatus: status => (status >= 200 && status < 500),
                headers: {
                    "Authorization": `Bearer ${this.state.auth.authenticateToken}`
                }
            })
        if (result.status === 200) {
            await commit('setTasks', result.data.tasks)
        } else {
            throw({status: result.status, message: result.data.message})
        }
    },

    async GetCrossword({commit}, taskId) {
        const result = await axios.get('/tasks/' + taskId,
            {
                validateStatus: status => (status >= 200 && status < 500),
                headers: {
                    "Authorization": `Bearer ${this.state.auth.authenticateToken}`
                }
            })
        if (result.status === 200) {
            await commit('setCrossword', {taskId: taskId, crossword:result.data.crossword})
        } else {
            throw({status: result.status, message: result.data.message})
        }
    }
};
const mutations = {
    setTasks(state, tasks) {
        state.tasks = tasks
    },
    setCrossword(state, {taskId, crossword}) {
        state.crosswords[taskId] = crossword
    },
    logout(state) {
        state.tasks = null
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
