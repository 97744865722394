<template>
  <div class="button-exit" @click="$emit('click')">
    <img src="../assets/img/close.svg" :alt="$t('button.close')" class="button-exit-img">
  </div>
</template>

<script>

export default {
  name: 'ButtonClose',
  components: {}
};
</script>

<style>
.button-exit-img {
  max-width: 10vh;
  max-height: 10vw;
  opacity:0.8;
}
.button-exit-img:hover{
  opacity:1;
}
</style>
