<template>
  <main>
    <h2>Sign Up</h2>
    <form @submit.prevent="submit">
      <p>
        <label for="email">{{$t('signup.email')}}:</label>
        <input type="email" id="email" name="email" v-model="form.email">
      </p>
      <p>
        <label for="password">{{$t('signup.password')}}:</label>
        <input type="password" id="password" name="password" v-model="form.password">
      </p>
      <p>
        <label for="password">{{$t('signup.password2')}}:</label>
        <input type="password" id="password2" name="password2" v-model="form.password2">
      </p>
      <p>
        <input type="submit" :value="$t('signup.submit')">
      </p>
    </form>
    <p v-if="error && error.messageI8n" class="error">{{$t(error.messageI8n, error.optsI8n)}}</p>
    <p v-else-if="error && isServerError()" class="error" v-t="'signup.errors.unavailable'"></p>
    <p v-else-if="error && !isServerError()" class="error" v-t="'signup.errors.emailAlreadyUsed'"></p>
  </main>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Signup",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    ...mapActions(["signupAction"]),
    async submit() {
      this.error = null

      if(this.form.password.length < 6){
        this.error = {messageI8n: 'signup.errors.passwordTooShort', optsI8n: {'minPasswordLength': 6}}
        return
      }
      if(this.form.password != this.form.password2){
        this.error = {messageI8n: 'signup.errors.passwordsDontMatch'}
        return
      }
        const user = {
          email: this.form.email,
          password: this.form.password,
        }
        try {
          await this.signupAction(user);
          await this.$router.push("/");
        } catch (error) {
          this.error = error
        }
    },
    isServerError() {
      return ((this.error.config && this.error.config.url) || this.error.code >= 500)
    }
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
label {
  font-size:160%;
  text-transform: uppercase;
  text-align: right;
  padding-right: 0.5em;
  display: inline-block;
  min-width: 10em;
}
input[type=submit] {
  cursor: pointer;
}
input {
  font-size: 150%;
  margin: 5px;
  padding: 10px;
  border-radius:0.2em;
}
.error {
  color: red;
}
</style>
