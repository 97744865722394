<template>
  <ErrorMessageBox v-if="error" :error="error"></ErrorMessageBox>
  <div v-else class="grid-create">
    <div class="grid-create-grid">
      <div class="grid-create-menu-row">
        <ButtonClose @click="$emit('exit')"></ButtonClose>
        <h3 v-t="'grids.new'"></h3>
        <ButtonSave @click="submitGrid()" :objectName="$t('grids.object')"></ButtonSave>
      </div>
      <div class="grid-create-grid-row1">
        <div class="grid-create-grid-slider-horizontal">
          <input type="range" min="1" :max="maxWidth" id="width" v-model="width">
        </div>
        <div class="grid-create-grid-row2">
          <div class="grid-create-grid-table">
            <table class="grid-create-table" :key="componentKey">
              <tr v-for="x in range(1, height)" :key="x">
                <td v-for="y in range(1, width)" :key="x + '_' + y" @click="toggle(x,y)"
                    :class="table[x][y] == 'X' ? 'filled' : 'empty'"></td>
              </tr>
            </table>
          </div>
          <div class="grid-create-grid-slider-vertical">
            <p><input orient="vertical" type="range" min="1" :max="maxHeight" id="height" v-model="height"></p>
          </div>
        </div>
      </div>
      <HelpBox context="grids.create"></HelpBox>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import ButtonClose from "@/components/ButtonClose";
import ButtonSave from "@/components/ButtonSave";
import ErrorMessageBox from "@/components/ErrorMessageBox";
import HelpBox from "@/components/HelpBox";

export default {
  name: 'GridCreate',
  components: {ButtonSave, ButtonClose, ErrorMessageBox, HelpBox},
  data() {
    return {
      maxWidth: 15,
      maxHeight: 15,
      width: 5,
      height: 5,
      table: [],
      componentKey: 0,
      error: null
    }
  },
  created: function () {
    this.table = []
    for (let y = 0; y <= this.maxHeight; y++) {
      this.table.push([])
      for (let x = 0; x <= this.maxWidth; x++) {
        if (y == 1 || x == 1) {
          this.table[y].push('X')
        } else {
          this.table[y].push(' ')
        }
      }
    }
  },
  computed: {
    ...mapGetters({Grids: "StateGrids"}),
  },
  methods: {
    ...mapActions(["CreateGrid"]),
    async submitGrid() {
      try {
        // Serialize this grid
        let bitmap = ""
        for (let y = 1; y <= this.height; y++) {
          for (let x = 1; x <= this.width; x++) {
            bitmap += this.table[x][y]
          }
        }
        const gridObject = {
          width: this.width,
          height: this.height,
          bitmap: bitmap
        }
        await this.CreateGrid(gridObject);
        this.$emit('created')
      } catch (error) {
        if (error.status == 401) {
          await this.$store.dispatch('logoutAction')
          await this.$router.push('/login')
        } else {
          this.error = error
          this.$emit("error")
        }
      }
    },
    range(start, stop, step) {
      let a = [start], b = start;
      while (b < stop) {
        a.push(b += step || 1);
      }
      return a;
    },
    toggle(x, y) {
      if (this.table[x][y] == 'X') {
        this.table[x][y] = ' '
      } else {
        this.table[x][y] = 'X'
      }
      this.componentKey++
    }
  }
};
</script>

<style>
.grid-create-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 25em;
  margin: 0 auto;
}

.grid-create-menu-row {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-self: center;
  justify-self: center;
}

.grid-create-grid-row1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.grid-create-grid-slider-horizontal {
  flex-shrink: 1;
}

.grid-create-grid-row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 1;
  min-height: 15.5em;
}

.grid-create-grid-slider-vertical {
  flex-shrink: 1;
  max-height: 3em;
  height: 60%;
}

.grid-create-grid-slider-vertical input[type=range] {
  transform: rotate(180deg);
  max-height: 8em;
  height: 8em;
}

.grid-create input[type=range] {
  width: 50%;
}

.grid-create-table {
  border-collapse: collapse;
  flex: 1;
}

.grid-create-table td {
  width: 1em;
  height: 1em;
  padding: 0;
  border: 1px solid #808080;
}

.grid-create-table td.filled {
  background-color: black;
  color: black;
}

.grid-create-table td.empty {
  background-color: white;
  color: black;
}


</style>
