<template>
  <main>
    <h2 v-t="'login.title'"></h2>
    <form @submit.prevent="submit">
      <p>
        <label for="email">{{ $t('login.email') }}:</label>
        <input type="email" id="email" name="email" v-model="form.email">
      </p>
      <p>
        <label for="password">{{ $t('login.password') }}:</label>
        <input type="password" id="password" name="password" v-model="form.password">
      </p>
      <p>
        <input type="submit" :value="$t('login.submit')">
      </p>
    </form>
    <p v-if="error && isServerError()" id="error" v-t="'login.errors.unavailable'"></p>
    <p v-if="error && !isServerError()" id="error" v-t="'login.errors.unauthenticated'"></p>
  </main>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: false,
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions(["loginAction"]),
    async submit() {
      const User = {
        email: this.form.email,
        password: this.form.password,
      }
      try {
        await this.loginAction(User);
        await this.$router.push("/");
        this.error = null
      } catch (error) {
        this.error = error
      }
    },
    isServerError() {
      return ((this.error.config && this.error.config.url) || this.error.code >= 500)
    }
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

label {
  font-size: 160%;
  text-transform: uppercase;
  text-align: right;
  padding-right: 0.5em;
  display: inline-block;
  min-width: 10em;
}

input[type=submit] {
  cursor: pointer;
}

input {
  font-size: 150%;
  margin: 5px;
  padding: 10px;
  border-radius: 0.2em;
}

#error {
  color: red;
}
</style>
