//store/modules/auth.js
import axios from 'axios';

const state = {
    user: null,
    authenticateToken: null
};
const getters = {
    isAuthenticated: state => !!state.user,
    hasGrids: state => !!state.user && state.user.gridsCount>0,
    hasTasks: state => !!state.user && state.user.tasksCount>0,
    StateUser: state => state.user,
};
const actions = {
    async signupAction({commit}, form) {
        const result = await axios.post('/users/signup', form,
            {
                validateStatus: status => (status >= 200 && status < 500)
            })
        if (result.status === 200 && result.data.authenticateToken) {
            await commit('setAuthenticateToken', result.data.authenticateToken)
            await commit('setUser', result.data.user)
            return true
        } else {
            throw({status: result.status, message: result.data.message})
        }
    },
    async loginAction({commit}, User) {
        const result = await axios.post('/users/login', User,
            {
                validateStatus: status => (status >= 200 && status < 500)
            })
        if (result.status === 200 && result.data.authenticateToken) {
            await commit('setAuthenticateToken', result.data.authenticateToken)
            await commit('setUser', result.data.user)
            return true
        } else {
            throw({status: result.status, message: result.data.message})
        }
    },
    async logoutAction({commit}) {
        commit('logout', null)
    }

};
const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setAuthenticateToken(state, authenticateToken) {
        state.authenticateToken = authenticateToken
    },
    logout(state) {
        state.user = null
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
