<template>
<div class="error-message-box">{{ translatedError() }}</div>
</template>
<script>

export default {
  name: "ErrorMessageBox",
  components: {},
  props: {'error': Object},
  methods: {
    translatedError() {
      if (this.error.messageI8n) {
        return this.$t(this.error.messageI8n)
      } else {
        return this.error.message
      }
    }
  }
};
</script>

<style>
.error-message-box {
  border: 2px solid #ff9999;
  background-color: #ffaaaa;
  padding: 1em;
  font-weight: bold;
}
</style>
