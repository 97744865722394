<template>
  <div class="button-save" @click="$emit('click')">
    <img src="../assets/img/save.svg" :alt="$t('button.save', {objectName: objectName})" class="button-save-img">
  </div>
</template>

<script>

export default {
  name: 'ButtonSave',
  components: {},
  props: ['objectName']
};
</script>

<style>
.button-save-img {
  max-width: 10vh;
  max-height: 10vw;
  opacity:0.8;
}
.button-save-img:hover{
  opacity:1;
}
</style>
