<template>
  <main>
    <ErrorMessageBox v-if="error" :error="error"></ErrorMessageBox>
    <Crossword v-else :crosswordObject="crossword"></Crossword>
  </main>
</template>

<script>
import Crossword from '@/components/Crossword.vue'
import ErrorMessageBox from "@/components/ErrorMessageBox";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "CrosswordView",
  components: {Crossword, ErrorMessageBox},
  created: async function () {
    try {
      await this.GetCrossword(this.$route.params.id)
    } catch (error) {
      if (error.status == 401) {
        await this.$store.dispatch('logoutAction')
        await this.$router.push('/login')
      } else {
        this.error = error
        this.$emit("error")
      }
    }
    this.crossword = this.StateCrosswordById(this.$route.params.id)
  },
  data() {
    return {
      crossword: {width: 1, height: 1, rows: []},
      error: null
    }
  },
  computed: {
    ...mapGetters({StateCrosswordById: "StateCrosswordById"})
  },
  methods: {
    ...mapActions(["GetCrossword"])
  }
};
</script>

<style>
main {
  max-width: 80em;
  margin: 0 auto;
  position: relative;
}
</style>
