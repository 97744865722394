<template>
  <div class="crossword-container">
    <div class="crossword">
      <div class="crossword-grid">
        <div class="row" v-for="row in noZeroIndex(rows)" :key="row.index">
          <div
              v-bind:class="{cell: true, 'cell-description': column.isDescription, 'cell-char': column.isChar, 'cell-empty': !column.isChar && !column.isDescription }"
              v-for="column in noZeroIndex(row.columns)" :key="column.index">
            <div class="content-outter">
              <div class="description" v-if="Object.keys(column.descriptions).length > 0">
                <div v-for="descriptionType in Object.keys(column.descriptions)" :key="descriptionType">
                  <div :class="`description-${descriptionType}`">{{ column.descriptions[descriptionType] }}</div>
                </div>
              </div>
              <div v-else>
                <div class="content-inner"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="crossword-legend">
        <li v-for="wordSpace in crosswordObject.word_spaces" :key="wordSpace.id">
          <strong>{{wordSpace.id}}</strong>: <span>{{wordSpace.meaning}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Crossword',
  props: {
    crosswordObject: Object
  },
  data: function () {
    return {
      width: Number,
      height: Number,
      rows: [],
      isDescription: false,
      isChar: false,
      isEmpty: false,
    }
  },
  watch: {
    crosswordObject(newCrosswordObject) {
      if (!newCrosswordObject) {
        return
      }
      this.width = newCrosswordObject.width
      this.height = newCrosswordObject.height

      // transform
      this.rows = []
      for (let y = 0; y < this.height + 1; y++) {
        let row = {index: y, columns: []}
        for (let x = 0; x < this.width + 1; x++) {
          row.columns.push({index: x, descriptions: {}})
        }
        this.rows.push(row)
      }

      let wordSpaceCnt = 1
      for (const wordSpace of newCrosswordObject.word_spaces) {
        let descColumn = null
        let charColumns = []
        if (wordSpace.type == "horizontal") {
          descColumn = this.rows[wordSpace.start[1]].columns[wordSpace.start[0] - 1]
          for (let offset = 0; offset < wordSpace.length; offset++) {
            charColumns.push(this.rows[wordSpace.start[1]].columns[wordSpace.start[0] + offset])
          }
        } else if (wordSpace.type == "vertical") {
          descColumn = this.rows[wordSpace.start[1] - 1].columns[wordSpace.start[0]]

          for (let offset = 0; offset < wordSpace.length; offset++) {
            charColumns.push(this.rows[wordSpace.start[1] + offset].columns[wordSpace.start[0]])
          }
        } else {
          throw "Inconsistent Crossword data"
        }

        wordSpace.id = wordSpaceCnt++
        descColumn.descriptions[wordSpace.type] = wordSpace.id
        descColumn.isDescription = true
        charColumns.forEach(function (charColumn) {
          charColumn.content = " "
          charColumn.isChar = true
        })
      }
    }
  },
  methods: {
    noZeroIndex: function (arr) {
      return arr.filter(item => item.index > 0)
    },
    allCels(){
      return this.rows.flat()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.crossword {
  font-family: 'Times New Roman', Serif;
  -webkit-font-smoothing: antialiased;
}

.crossword {
  display:flex;
  flex-direction:row
}

.cell {
  padding: 0.3em;
  flex: 1 0 auto;
  border: 1px solid #808080
}


.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
}

.cell:before {
  content: '';
  float: left;
  padding-top: 100%;
}

.cell {
  border: 1px solid black;
  flex: 1 0 auto;
  color: white;
  flex: 1 0 auto;
  height: auto;
  position: relative;
}

.cell .content-outter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
}

.cell .content-inner {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-shadow: 0.05em 0.5em 0.9em #666666;
  text-transform: uppercase
}


/* @media (min-width: 48rem) { */
.cell {
  width: 5%;
}
.cell-empty {
  border-style: none !important;
  background-color: #ffffff;
}

.cell-char {
  background-color: #f0f0f0;
}

.cell-description {
  background-color: #c0c0c0;
  color: black;
}

.description-vertical::after {
  content: '↓'
}

.description-horizontal::after {
  content: '→'
}

.description {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.cell-char, .cell-description {
  box-shadow: inset 0 0 1em 0.5em rgba(100, 100, 100, 0.3);
}

.crossword {width: 90%;}
.crossword-grid { flex: 1 0;}
.crossword-legend {max-width:20em !important; }
.crossword-legend {list-style-type:none}
.crossword-legend li{padding-left:0}

</style>
