import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import Home from '../views/Home.vue'
import CrosswordView from '../views/CrosswordView.vue'
import Grids from '../views/Grids.vue'
import Signup from '../views/Signup'
import Login from '../views/Login'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiesAuth: true,
            title: "Crosswords"
        },
    },
    {
        path: '/grids',
        name: 'Grids',
        component: Grids,
        meta: {
            requiesAuth: true,
            title: "Grids"
        },
    },
    {
        path: '/crossword/:id',
        name: 'Crossword',
        component: CrosswordView,
        meta: {
            title: "Crossword"
        },
    },
    {
        path: '/signup',
        name: "Signup",
        component: Signup,
        meta: {
            title: "Signup"
        }
    },
    {
        path: '/login',
        name: "Login",
        component: Login,
        meta: {
            title: "Login"
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const projectTitle = "Crossword Generator"
    if (to.matched.some(record => record.meta.title)) {
        document.title = [to.matched.filter(record => record.meta.title)[0].meta.title, projectTitle].join(" | ");
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
