//store/modules/auth.js
import axios from 'axios';

const state = {
    grids: null
};
const getters = {
    StateGrids: state => state.grids
};
const actions = {
    async CreateGrid({dispatch}, gridObject) {
        const result = await axios.post('grids', gridObject, {
            validateStatus: status => (status >= 200 && status < 500),
            headers: {
                "Authorization": `Bearer ${this.state.auth.authenticateToken}`
            }
        })
        if (result.status === 200 ) {
            // refresh list
            await dispatch('GetGrids')
        } else {
            throw({status: result.status, message: result.data.message})
        }
    },

    async GetGrids({commit}) {
        const user = this.state.auth.user
        const result = await axios.get('/users/'+user.id+'/grids',
            {
                validateStatus: status => (status >= 200 && status < 500),
                headers: {
                    "Authorization": `Bearer ${this.state.auth.authenticateToken}`
                }
            })
        if (result.status === 200 ) {
            await commit('setGrids', result.data.grids)
        } else {
            throw({status: result.status, message: result.data.message})
        }
    }
};
const mutations = {
    setGrids(state, grids) {
        state.grids = grids
    },
    logout(state) {
        state.grids = null
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
