<template>
  <main>
    <TaskList v-if="this.$store.getters.isAuthenticated"></TaskList>
    <div v-else v-t="'home.pleaseLogin'"></div>
  </main>
</template>

<script>
import TaskList from '@/components/TaskList.vue'

export default {
  name: "Home",
  components: {TaskList},
};
</script>

<style>
</style>
